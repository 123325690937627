@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	.font-outline-2 {
		-webkit-text-stroke: .5px black;
	}

	.font-outline-4 {
		-webkit-text-stroke: 4px black;
	}
}

@font-face {
	font-family: 'Anton';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		/* url('./assets/satoshi/Satoshi-Regular.woff2') format('woff2'), */
		url('./assets/Anton/Anton-Regular.ttf')
}

@font-face {
	font-family: 'SpaceGrotesk';
	font-style: normal;
	font-weight: 400;
	font-display: swap;
	src:
		/* url('./assets/satoshi/Satoshi-Regular.woff2') format('woff2'), */
		url('./assets/Space_Grotesk/static/SpaceGrotesk-Regular.ttf')
}


html {
	font-family: 'SpaceGrotesk';
	overflow: hidden;
}

body {
	margin: 0;
	font-family: 'SpaceGrotesk';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.custom-gradient {
	background-image: linear-gradient(to right, #ef4444, #b91c1c, #ef4444, #b91c1c);
}

.custom-gradient-2 {
	background-image: linear-gradient(to right, #090909, #434343, #5b5b5b, #353535);
}